<template>
  <section>
    <PanelHeaderEquipmentList
      v-bind:title="title"
      v-bind:nav="nav"
      icon="fa fa-dashboard"
      :connector_id="connectorId"
      @tab="tab = $event"
    />

    <div
      class="tab-pane"
      role="tabpanel"
      aria-labelledby=""
      id="edit"
      :class="tab == '#edit' ? 'active' : ''"
    >
      <FormAlarm
        v-bind:connector_id="connector_id"
        v-bind:device_id="device_id"
        v-bind:data_id="data_id"
        v-bind:alarm_id="alarm_id"
        v-on:titleChanged="onTitleChanged"
        v-on:navChanged="onNavChanged"
        @loading="(state) => this.$emit('loading', state)"
        v-on:noData="$emit('noData')"
        :key="formAlarmKey"
        @updateKey="(key) => (formAlarmKey = key || Date.now())"
      />
    </div>
    <div
      class="tab-pane"
      role="tabpanel"
      aria-labelledby=""
      id="list"
      :class="tab == '#list' ? 'active' : ''"
    ></div>
  </section>
</template>

<script>
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import FormAlarm from "@/components/registration/form-alarm.vue";
export default {
  name: "DashboardEditAlarmForm",
  components: {
    FormAlarm,
    PanelHeaderEquipmentList
  },
  props: {
    connector_id: {
      type: Number,
      required: true,
      default: 0
    },
    device_id: {
      type: Number,
      required: true,
      default: 0
    },
    data_id: {
      type: Number,
      required: true,
      default: 0
    },
    alarm_id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      title: "loading",
      tab: "#edit",
      nav: null,
      showNextLevel: false,
      formAlarmKey: this.alarm_id
    };
  },
  computed: {
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    },
    dataId() {
      return parseInt(this.data_id || this.$route.params.data_id || 0);
    },
    alarmId() {
      return parseInt(this.alarm_id || this.$route.params.alarm_id || 0);
    }
  },
  methods: {
    onTitleChanged(title) {
      // this.$emit("titleChanged", this.$tc("alarm") + ": " + title);
      this.title = this.$tc("alarm") + ": " + title;
    },
    onNavChanged(nav) {
      // this.$emit("navChanged", nav);
      this.$set(this, "nav", nav);
    }
  },
  mounted() {},
  beforeCreate() {
    this.$emit("rule", "AlarmeCadastro");
  }
};
</script>

<style scoped>
section > .tab-pane {
  margin: 30px 0 30px 0;
  padding: 10px 10px 50px 10px;
}
section > .tab-pane-list {
  padding: 0;
}

section > .tab-pane-list > .h4 {
  margin-bottom: -30px;
  padding: 0 10px;
}
</style>
